/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/javascript.js to edit this template
 */


import { useState, useEffect }
    from "react";
import { useParams, useLocation }
    from 'react-router-dom';

import { Container, Row, Col, Tab, Nav }
    from 'react-bootstrap';
import { library }
    from '@fortawesome/fontawesome-svg-core';
import { fas }
    from '@fortawesome/free-solid-svg-icons';
import { fab }
    from '@fortawesome/free-brands-svg-icons';
import { far }
    from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
    from '@fortawesome/react-fontawesome';

library.add(fas, fab, far);
function DeliveryInfo() {

    return (
        <div>
            <Row><h2>Delivery Information</h2></Row>
            <br />
            <Row>
                <p>
                    <b>What are the delivery charges?</b><br />

                    Delivery charges depend on your location. Orders above ₹1000 qualify for free delivery. Please Note that delivery fees may vary between Express and Standard Delivery options.
                    <br />

                    <br />

                    <b>What types of deliveries available?</b><br />

                    We offer two delivery options: Standard Delivery and Express Delivery. The availability of Each option depends on the customer's location. Additionally, a takeaway option is available for customers who prefer to pick up their order directly from the store.
                    <br />


                    <br />
                    <b>What is standard delivery?</b><br />

                    You have probably noticed varying estimated delivery times for each locations to the products you are interested in. Delivery times are influenced by product availability, your shipping destination and the courier partner&#39;s time-to-deliver in your location. Please enter your default pin code (you don&#39;t have to enter it every single time) to know more accurate delivery times.

                    <br />

                    <br />
                    <b>What is Express Delivery?</b><br />

                    Express Delivery ensures your order is delivered within a convenient 1-hour time slot, fulfilling your need for speed and convenience.
                    <br />

                    <br />
                    <b>How Does Express Delivery Work?</b><br />

                    For instance, if you place an order at 12:55 PM, you can select any available delivery slot starting from that time, such as 1PM - 4PM. Your Order will be delivered within the chosen time frame, and you'll receive an Email or SMS notification once your order has been dispatched.
                    <br />

                    <br />
                    <b>Available Delivery Slots:</b><br />
                    <ul>
                        <li>10AM - 1PM ( Fresh Slot )</li>
                        <li>1PM - 4PM (Afternoon Slot)</li>
                        <li>4PM - 9PM (Meant for Working Peoples)</li>
                    </ul>
                   
                    <b>What is Take Away?</b><br />

                    Facing issues with delivery slots? No problem! We've got you covered. Simply Place your order online and pick up your products through our convenient takeaway service. You can place your order at your nearest branch using our Website or mobile app.

                    <br />

                    <br />
                    <b>Why is the COD option not offered in my location?</b><br />

                    Availability of COD depends on the ability of our courier partner servicing your location to accept cash as payment at the time of delivery.
                    <br />

                    <br />
                    Our courier partners have limits on the cash amount payable on delivery depending on the destination and your order value might have exceeded this limit. Please enter your pin code on the product page to check if COD is available in your location.
                    <br />

                    <br />
                    <b>Does Grace Super Market deliver out of Chennai?</b><br />

                    Currently, Grace Supermarket delivers only within Chennai. However, for bulk orders or Specific products with a longer shelf life, we may consider delivery outside Chennai for an additional delivery charge. Please contact us to discuss your Requirements, and we will get back to you.

                </p>
            </Row>
        </div>

    );
}

export default DeliveryInfo;