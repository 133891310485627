import { useDispatch, useSelector }
    from "react-redux";
import { useState, useEffect }
    from "react";
import { useNavigate } from "react-router-dom";
import GoogleMapModal from "../../common/liveGmap";
import { fetchDeliverySlot, fetchDeliveryAddress, fetchTakeawaySlot, checkCartStatus, clearStock }
    from "../../features/cartSlice";
import { clearState, deleteShippingAddress } from "../../features/profileSlice";
import axios from 'axios';
import { Container, Accordion, Row, Col, Image, Breadcrumb, Table, Card, ListGroup, Tab, Tabs, Nav, Button, Form, Modal, Alert, ToastContainer, Toast }
    from 'react-bootstrap';
import { library }
    from '@fortawesome/fontawesome-svg-core';
import { fas }
    from '@fortawesome/free-solid-svg-icons';
import { fab }
    from '@fortawesome/free-brands-svg-icons';
import { far }
    from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
    from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';
import moment from 'moment';
import { set } from "lodash";
library.add(fas, fab, far);
function ShippingAddress(props) {
    const [key, setKey] = useState('');
    const [address, setAddress] = useState(null);
    const [takeAwayBranch, setTakeAwayBranch] = useState(JSON.parse(localStorage.getItem('selectedBranch')));
    const dispatch = useDispatch();
    const { deliverySlot, deliveryAddress, timeSlot, error, response, outOfStock } = useSelector(
        (state) => state.cart
    );
    const { updateState, success } = useSelector(
        (state) => state.profile
    );
    const [timeSlots, setTimeSlots] = useState();

    const [addresss, setAddresss] = useState(null);
    const [addressModalShow, setAddressModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [outStockShow, setOutStockShow] = useState(false);
    const [homeEnable, setHomeEnable] = useState(false);
    // const [deliveryTypeActive, setDeliveryTypeActive] = useState("0");
    const [deliveryTypeActive, setDeliveryTypeActive] = useState(['0', '1']);
    const [takeAwayEnable, setTakeAwayEnable] = useState(false);
    const [deliveryOption, setDeliveryOption] = useState(null);
    const [showSlot, setShowSlot] = useState(false);
    const [isStandardDeliverySelected, setIsStandardDeliverySelected] = useState(false);
    const [finalAmount, setFinalAmount] = useState(0);
    const [isfirstDeliveryOptionSelected, setIsfirstDeliveryOptionSelected] = useState(null);

    const navigate = useNavigate();
    useEffect(() => {
        setFinalAmount(parseFloat(props.cartAmounts) - parseFloat(props.totalDiscount));
        let getData = setTimeout(() => {
            dispatch(fetchDeliverySlot());
            dispatch(fetchDeliveryAddress());
        }, 0);
        return () => clearTimeout(getData);
    }, [dispatch]);

    const handleDateChange = (e) => {
        // console.log('handleDateChange', e.target.value);
        setTimeSlots(deliverySlot[e.target.value]);
        props.setdeliveryDetails({ ...props.deliveryDetails, deliveryDate: e.target.value });
    }
    const handleTimeChange = (e) => {
        props.setdeliveryDetails({ ...props.deliveryDetails, slotId: parseInt(e.target.value) });
    }

    const handleAddressEdit = (addr) => {
        if (addr) {
            setIsEdit(true);
            setAddresss(addr);
        }
        setAddressModalShow(true);
    };

    const handleAddressDelete = (addrId) => {
        setDeleteModalShow(true);
        setDeleteId(addrId);
    };
    const confirmDelete = () => {
        setDeleteModalShow(false);
        dispatch(clearState());
        dispatch(deleteShippingAddress(deleteId));
    };

    const redirectCart = () => {
        setOutStockShow(false);
        window.location.href = "/cart";
    }
    // Get Branch API by Lat&Lng
    const fetchBranchesByLatLng = async (latitude, longitude) => {
        try {
            let brchUrl = process.env.REACT_APP_BASE_URL + `/branches/getBylatLong`;
            const response = await axios.post(brchUrl, {
                latitude: latitude,
                longitude: longitude,
            });
            const branch = response.data.data[0];
            if (branch) {
                localStorage.setItem('selectedBranch', JSON.stringify(branch));
                return branch;
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error fetching branches:', error);
            return null;
        }
    };

    const fetchDeliveryOptions = async () => {
        var data = {
            "branchId": takeAwayBranch.id,
            "addressId": address,
            "cartValue": (props.cartAmounts - props.totalDiscount).toFixed(2)
        };
        console.log(data, 'dataDeliveryoption Params');
        const responseDelivery = await axios.post(process.env.REACT_APP_BASE_URL + "/delivery/getDeliveryOptions", data);
        setDeliveryOption(responseDelivery.data.data);
    };
    const changeDeliveryOptions = async (type, amount, slotId) => {
        props.setShippingCharge(parseInt(amount));
        props.setShippingShow(true);
        if (type == 1) {
            if (timeSlots && timeSlots.length > 0) {
                props.setdeliveryDetails({ ...props.deliveryDetails, deliveryType: type, slotId: timeSlots[0].id });
            } else {
                props.setdeliveryDetails({ ...props.deliveryDetails, deliveryType: type });
            }
        } else {
            props.setdeliveryDetails({ ...props.deliveryDetails, deliveryType: type, slotId: slotId });

        }
    }

    useEffect(() => {
        if (success) {
            dispatch(fetchDeliveryAddress());
            // setDeliveryTypeActive(1);
        }
    }, [success]);
    useEffect(() => {
        // console.log(timeSlot,'TimeSlotsssss')
        setTimeSlots(timeSlot);
    }, [timeSlot]);

    useEffect(() => {
        if (deliveryAddress.length > 0) {
            const addressId = JSON.parse(localStorage.getItem("selectedAddress"));
            setAddress(addressId ? addressId.address : deliveryAddress[0].id);
            props.setdeliveryDetails({ ...props.deliveryDetails, shippingId: (addressId ? addressId.address : deliveryAddress[0].id) });
        }

    }, [deliveryAddress]);

    useEffect(() => {
        let defaultDate = null;
        const firstSlotKey = Object.keys(deliverySlot)[0]; // Get the first date key
        if (firstSlotKey && deliverySlot[firstSlotKey].length > 0) {
            defaultDate = firstSlotKey;
        } else {
            const secondSlotKey = Object.keys(deliverySlot)[1];
            if (secondSlotKey) {
                defaultDate = secondSlotKey;
            }
        }
        props.setdeliveryDetails({ ...props.deliveryDetails, deliveryDate: defaultDate, shippingId: address });
        setTimeSlots(deliverySlot[defaultDate]);
    }, [deliverySlot, address]);
    useEffect(() => {
        props.setdeliveryDetails({ ...props.deliveryDetails, shippingId: address });
        const addressId = JSON.parse(localStorage.getItem("selectedAddress"));
        if (addressId && address !== addressId.address && deliveryAddress.length > 0) {
            let addr = deliveryAddress.find(x => x.id === address) || null;
            let branch;
            if (addr) {
                (async () => {
                    branch = await fetchBranchesByLatLng(addr.latitude, addr.longitude);
                    if (!branch) {
                        // alert("Cant delivery to this location");
                        props.setToast(true);
                        props.setToastError('Cant delivery to this location');
                    } else {
                        setDeliveryTypeActive(["1"]);
                        setTakeAwayBranch(branch);
                        localStorage.setItem('selectedAddress', JSON.stringify({ address: addr.id }));
                        localStorage.setItem('selectedLocation', JSON.stringify({ area: addr.area, pincode: addr.pincode, lat: addr.latitude, lng: addr.longitude }));
                        dispatch(clearStock());
                        dispatch(checkCartStatus());

                    }
                })();
            } else {

            }
        } else if (!addressId && address && deliveryAddress.length > 0) {
            let addr = deliveryAddress.find(x => x.id === address) || null;
            let branch;
            if (addr) {
                (async () => {
                    branch = await fetchBranchesByLatLng(addr.latitude, addr.longitude);
                    if (!branch) {
                        // alert("Cant delivery to this location");
                        props.setToast(true);
                        props.setToastError('Cant delivery to this location');
                    } else {
                        props.setdeliveryDetails({ ...props.deliveryDetails, shippingId: address });
                        setDeliveryTypeActive(["1"]);
                        setTakeAwayBranch(branch);
                        localStorage.setItem('selectedAddress', JSON.stringify({ address: addr.id }));
                        localStorage.setItem('selectedLocation', JSON.stringify({ area: addr.area, pincode: addr.pincode, lat: addr.latitude, lng: addr.longitude }));
                        dispatch(clearStock());
                        dispatch(checkCartStatus());
                    }
                })();
            }
        } else {
            props.setdeliveryDetails({ ...props.deliveryDetails, shippingId: address });
        }
    }, [address]);
    useEffect(() => {
        console.log(deliveryTypeActive, 'deliveryTypeActive;;;;;;;');
        if (deliveryTypeActive.includes('1') && address) {
            fetchDeliveryOptions();
        }
    }, [deliveryTypeActive, address])
    useEffect(() => {
        if (outOfStock) {
            props.setdeliveryDetails({ ...props.deliveryDetails, shippingId: 0 });
            setOutStockShow(true);
        } else {
            props.setdeliveryDetails({ ...props.deliveryDetails, shippingId: address });
        }
    }, [outOfStock])
    useEffect(() => {
        if (timeSlots && timeSlots.length > 0) {
            props.setdeliveryDetails({ ...props.deliveryDetails, slotId: timeSlots[0].id });
        }
    }, [timeSlots]);
    useEffect(() => {
        if (key == "takeAway") {
            props.setShippingCharge(parseInt(0.00));
            props.setdeliveryDetails({ ...props.deliveryDetails, deliveryType: 2 });
            dispatch(fetchTakeawaySlot());
        } else {
            props.setdeliveryDetails({ ...props.deliveryDetails, deliveryType: 1 });
            dispatch(fetchDeliverySlot());
        }
    }, [key]);

    useEffect(() => {
        if (takeAwayBranch.enable_home_del == "1") {
            setHomeEnable(true);
        } else {
            setHomeEnable(false);
        }
        if (takeAwayBranch.enable_take_away == "1") {
            setTakeAwayEnable(true);
        } else {
            setTakeAwayEnable(false);
        }
    }, [takeAwayBranch]);

    useEffect(() => {
        if (!homeEnable && !takeAwayEnable) {
            props.setProceedNext(false);
        } else {
            props.setProceedNext(true);
        }
        if (homeEnable) {
            setKey('home');
        } else if (takeAwayEnable) {
            setKey('takeAway');
        }

    }, [homeEnable, takeAwayEnable]);


    useEffect(() => {
        if ((!deliveryOption || deliveryOption.length === 0) && finalAmount >= 500) {
            setShowSlot(true);
            changeDeliveryOptions(1, "0.00");
            setIsStandardDeliverySelected(true);
        }else if(deliveryOption && deliveryOption.length >0 && (isfirstDeliveryOptionSelected === true || isfirstDeliveryOptionSelected === null) ){
            setShowSlot(false);
            setIsStandardDeliverySelected(false);
            changeDeliveryOptions(deliveryOption[0].deliveryConfigTypeId, deliveryOption[0].fee, deliveryOption[0].slotId);
            setIsfirstDeliveryOptionSelected(true);
            console.log('All Done');
        }
    }, [deliveryOption, finalAmount]);
    useEffect(() => {
        if (showSlot === false && deliveryOption === null) {
            props.setDisablePayButton(true);
        } else {
            props.setDisablePayButton(false);
        }
    }, [showSlot, deliveryOption])
    // console.log(timeSlots, 'timeSlots');
    return (
        <div>
            {(homeEnable || takeAwayEnable) && <Tabs
                activeKey={key}
                id="shipping-address-tabs"
                className="mb-3"
                onSelect={(k) => setKey(k)}
                justify
            >
                {homeEnable && <Tab eventKey="home" title="Home or Office Delivery">
                    {/* <Accordion activeKey={deliveryTypeActive}> */}
                    <Accordion activeKey={deliveryTypeActive} onSelect={(key) => setDeliveryTypeActive([key])}>
                        <Accordion.Item eventKey="0" onClick={() => setDeliveryTypeActive(["0"])}>
                            <Accordion.Header>Select Delivery Address</Accordion.Header>
                            <Accordion.Body>
                                <div className='col-12'>

                                    <Row><Button className="col-6 col-sm-3 ms-auto mb-3" onClick={() => handleAddressEdit(null)}><FontAwesomeIcon icon="fa-solid fa-plus" /> Add Address</Button></Row>
                                    <Row className="">
                                        {deliveryAddress && deliveryAddress.map((addresses, key) => (
                                            <Card key={`delivery-address-${key}`} className={"txtCard bg-body-tertiary col-11 col-sm-5 m-3 mb-0 mb-sm-3 cursor-pointer " + (address == addresses.id ? 'active-address' : '')}>
                                                <Card.Body onClick={(k) => setAddress(addresses.id)}>
                                                    <Form.Check type="radio" id={"check-api-radio-" + addresses.id}>
                                                        <Form.Check.Input className=" cursor-pointer " type="radio" checked={(address === addresses.id ? 'checked' : '')} />
                                                        <Form.Check.Label className=" cursor-pointer ">
                                                            <span className="address-name">{addresses.name}</span><br />
                                                            {addresses.address}<br />
                                                            {addresses.area}<br />
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Card.Body>
                                                <Card.Footer className="text-end">
                                                    <Card.Link className="text-decoration-none color-primary cursor-pointer" onClick={() => handleAddressEdit(addresses)}><FontAwesomeIcon icon="fa-solid fa-edit" /> Edit</Card.Link>
                                                    <Card.Link className="text-decoration-none color-primary cursor-pointer" onClick={() => handleAddressDelete(addresses.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /> Delete</Card.Link>
                                                </Card.Footer>
                                            </Card>

                                        ))}
                                        {deliveryAddress.length == 0 && <Alert className="text-center">Please add shipping address</Alert>}

                                    </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" onClick={() => setDeliveryTypeActive(["1"])}>
                            <Accordion.Header>Select Delivery Type</Accordion.Header>
                            <Accordion.Body>
                                {deliveryOption && deliveryOption.map((x, index) => (
                                    <Form.Check
                                        type="radio"
                                        id={`default-${index}`}
                                        name="deliverytype"
                                        label={
                                            x.deliveryConfigTypeId === 2
                                                ? <span><strong className="text-danger">Express Delivery</strong> {x.deliveryConfigTitle} - Rs.{x.fee}</span>
                                                : x.deliveryConfigTypeId === 4
                                                    ? `Standard Delivery - Rs.${x.fee}`
                                                    : `${x.deliveryConfigTitle} - Rs.${x.fee}`
                                        }
                                        onClick={() => {
                                            setShowSlot(false);
                                            setIsStandardDeliverySelected(false);
                                            setIsfirstDeliveryOptionSelected(true);
                                            changeDeliveryOptions(x.deliveryConfigTypeId, x.fee, x.slotId);
                                        }}
                                        className="mb-3"
                                        checked={isfirstDeliveryOptionSelected}
                                    />
                                ))}
                                {finalAmount >= 500 && <Form.Check
                                    type="radio"
                                    id="default"
                                    name="deliverytype"
                                    label="Regualr Delivery"
                                    checked={isStandardDeliverySelected}
                                    onClick={() => {
                                        setShowSlot(true);
                                        setIsStandardDeliverySelected(true);
                                        changeDeliveryOptions(1, "0.00");
                                        setIsfirstDeliveryOptionSelected(false);
                                    }}
                                />}
                                {showSlot === false && deliveryOption === null && <Alert className="text-center">No delivery slots available</Alert>}
                                {showSlot && <Row className='justify-content-center'>
                                    <Col md='6'>
                                        <Card className='bg-body-tertiary checkout-sa-card'>
                                            <Card.Body>
                                                <Card.Title>Select Delivery Date</Card.Title>
                                                {deliverySlot && Object.entries(deliverySlot).some(([key, val]) => val.length > 0) ? (
                                                    <Form.Select aria-label="Delivery date" className='mt-3' onChange={handleDateChange}>
                                                        {Object.entries(deliverySlot).map(([key, val]) => {
                                                            if (val.length > 0) {
                                                                return (
                                                                    <option key={key} value={key}>
                                                                        {moment(key, "MM/DD/YYYY").format("DD/MM/YYYY")}
                                                                    </option>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Form.Select>
                                                ) : (
                                                    <Alert className="text-center mt-3">No delivery slots available</Alert>
                                                )}
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                    <Col md='6'>
                                        <Card className='bg-body-tertiary checkout-sa-card'>
                                            <Card.Body>
                                                <Card.Title>Select Delivery Time</Card.Title>
                                                {/* Check if timeSlots has values */}
                                                {timeSlots && timeSlots.length > 0 ? (
                                                    <Form.Select aria-label="Delivery Time" className='mt-3' onChange={handleTimeChange}>
                                                        {timeSlots.map((slot) => (
                                                            <option key={slot.id} value={slot.id}>
                                                                {moment(slot.fromTime, "HH:mm:ss").format("ha")} to {moment(slot.toTime, "HH:mm:ss").format("ha")}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                ) : (
                                                    <Alert className="text-center mt-3">No delivery times available</Alert>
                                                )}
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                </Row>}


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                </Tab>}
                {takeAwayEnable && <Tab eventKey="takeAway" title="Take Away">
                    <Row className='justify-content-center'>
                        <Col md='12' className="my-5">
                            <Card className='bg-body-tertiary checkout-sa-card col-12 col-sm-6 m-auto text-center pt-4'>
                                <Card.Title>Pick Up Branch</Card.Title>
                                <Card.Body>
                                    {takeAwayBranch.name} - {takeAwayBranch.pincode}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md='6' className="mb-5">
                            <Card className='bg-body-tertiary checkout-sa-card'>
                                <Card.Body>
                                    <Card.Title>Select Delivery Date</Card.Title>
                                    <Form.Select aria-label="Delivery date" className='mt-3' onChange={handleDateChange}>
                                        {deliverySlot && Object.entries(deliverySlot).map(([key, val]) => (<option value={key}>{moment(key, "MM/DD/YYYY").format("DD/MM/YYYY")}</option>))}
                                    </Form.Select>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md='6'>
                            <Card className='bg-body-tertiary checkout-sa-card'>
                                <Card.Body>
                                    <Card.Title>Select Delivery Time</Card.Title>
                                    <Form.Select aria-label="Delivery Time" className='mt-3'>
                                        {timeSlots && timeSlots.map((slot) => (<option value={slot.id}>{moment(slot.fromTime, "HH:mm:ss").format("ha")} to {moment(slot.toTime, "HH:mm:ss").format("ha")}</option>))}
                                    </Form.Select>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab>}

            </Tabs>
            }
            {!homeEnable && !takeAwayEnable && <Alert className="text-center"> Home delivery and take away not available. <br /></Alert>}
            <GoogleMapModal
                showModal={addressModalShow}
                handleCloseModal={() => {
                    setAddressModalShow(false);
                    setIsEdit(false);
                    dispatch(fetchDeliveryAddress());
                }}
                edit={isEdit}
                editAddress={addresss}
                profile={true}
            />

            <Modal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                className="text-center"
            >
                <Modal.Header className="mx-auto">
                    <Modal.Title id="contained-modal-title-vcenter text-danger">
                        Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you Sure</h4>
                    <p>
                        You want to delete?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => confirmDelete()}>Delete</Button>
                    <Button className="bg-secondary border-secondary" onClick={() => setDeleteModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={outStockShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="text-center"
            >
                <Modal.Header className="mx-auto">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Some Products went out of stock.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={redirectCart}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default ShippingAddress;