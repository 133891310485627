import { useState, useEffect, useRef, useContext }
    from "react";
import { Container, Row, Col, AccordionContext, Image, Button, ButtonGroup, Model, Stack, Form, Badge, Nav, Navbar, NavDropdown, Carousel, Card, InputGroup, ListGroup, Breadcrumb, Dropdown, Accordion, useAccordionButton }
    from 'react-bootstrap';
import { Link, NavLink, useNavigate }
    from "react-router-dom";
import { library }
    from '@fortawesome/fontawesome-svg-core';
import { fas }
    from '@fortawesome/free-solid-svg-icons';
import { fab }
    from '@fortawesome/free-brands-svg-icons';
import { far }
    from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
    from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';


function formatCategoryName(categoryName) {
    // Replace hyphens with spaces and capitalize the first letter of each word
    return categoryName
        .replace(/-/g, ' ') // Replace hyphens with spaces
        .split(' ') // Split into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
        .join(' '); // Join words back with a space
}

function ParentCategory({ items, keys, setOffCanvasShow }) {
    const menuRef = useRef([]);
    const navigate = useNavigate();

    const handleMenuView = (catid) => {
        menuRef.current[catid].click();
    };

    const handleMenuClose = (catid) => {
        menuRef.current[catid].click();
    };

    const handleSlug = (catslug) => {
        navigate(`/ct/${catslug}`);
    };

    function ContextAwareToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <button
                type="button"
                className="float-end border-0"
                onClick={decoratedOnClick}
            >
                {isCurrentEventKey ? (
                    <FontAwesomeIcon
                        icon="fa-angle-up"
                        style={{ color: "#6c6868" }}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon="fa-chevron-down"
                        style={{ color: "#6c6868" }}
                    />
                )}
            </button>
        );
    }

    return (
        <div>
            <Dropdown className="d-none d-sm-block" as={ButtonGroup} onMouseLeave={() => handleMenuClose(items.categoryID)}>
                <Button className="category p-3" onMouseEnter={() => handleMenuView(items.categoryID)} onClick={() => handleSlug(items.slug)}>
                    {formatCategoryName(items.categoryName)}
                </Button>
                <Dropdown.Toggle split className="d-none" drop="down" ref={(ele) => menuRef.current[items.categoryID] = ele} id={`category-${items.categoryID}`} />
                <Dropdown.Menu className={`sub-category-${items.categoryID} mt-0 p-0`}>

                    {items.subcategories.map((subCat, index) => (
                        <Dropdown className="position-static p-0" as={ButtonGroup} key={index} drop="end">
                            <Button className="category text-start" onMouseEnter={() => handleMenuView(subCat.categoryID)} onClick={() => handleSlug(subCat.slug)}>
                                {formatCategoryName(subCat.categoryName)}
                            </Button>
                            <Dropdown.Toggle split variant="success" id="dropdown-split-basic" className="d-none" ref={(ele) => menuRef.current[subCat.categoryID] = ele} />
                            <Dropdown.Menu className="border-0 ms-0" style={{ minHeight: '100%' }}>
                                {subCat.subsubcategories.map((subsubCat, sindex) => (
                                    <Dropdown.Item eventKey={`${index}.${sindex}`} onClick={() => handleSlug(subsubCat.slug)}>
                                        {formatCategoryName(subsubCat.categoryName)}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    ))}

                </Dropdown.Menu>
            </Dropdown >
            <Card className="border-0 bg-transparent d-block d-sm-none">
                <Card.Header className="bg-transparent ps-0" >
                    <span onClick={() => {
                        handleSlug(items.slug);
                        setOffCanvasShow(false);
                    }}>
                        {formatCategoryName(items.categoryName)}
                    </span>
                    <ContextAwareToggle eventKey={`pc-${keys}`}>+</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`pc-${keys}`}>
                    <Accordion>
                        {items.subcategories.map((subCat, index) => (
                            <Card className="border-0 bg-transparent">
                                <Card.Header className="bg-transparent">
                                    <span onClick={() => {
                                        handleSlug(subCat.slug);
                                        setOffCanvasShow(false);
                                    }}>{formatCategoryName(subCat.categoryName)}</span>
                                    <ContextAwareToggle eventKey={`sc-${keys}.${index}`}>+</ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={`sc-${keys}.${index}`}>
                                    <div>
                                        {subCat.subsubcategories.map((subsubCat, sindex) => (
                                            <p eventKey={`${keys}.${index}.${sindex}`} className="my-2 ps-4" onClick={() => {
                                                handleSlug(subsubCat.slug);
                                                setOffCanvasShow(false);
                                            }}>{formatCategoryName(subsubCat.categoryName)}</p>
                                        ))}
                                    </div>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </Accordion.Collapse>
            </Card>
        </div>
    );
}

export default ParentCategory;
